.typeAnimator {
  display: inline;
  margin-right: 0;
}

.contentDiv {
  display: inline;
}

.ghostCursor {
  margin-top: auto;
  display: inline-block;
  width: 0.66em;
  height: 0.8em;
  background-color: grey;
  animation: blink 0.5s infinite;
  mix-blend-mode: difference;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
