.bioRoot {
  height: 100vh;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: space-evenly;
}

.bioRoot > div {
  font-size: 0.5em;
  margin-left: 5vw;
  margin-right: 5vw;
}

a:hover {
  cursor: none;
  animation: shake 0.5s infinite;
}

@media only screen and (max-width: 450px) {
  .bioRoot {
    height: 100vh;
  }

  .bioRoot > div {
    font-size: 0.25em;
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
