@import url("https://fonts.googleapis.com/css2?family=Prompt&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap");

body {
  margin: 0;
  font-family: "Major Mono Display", monospace;
  font-size: 10vh;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: none;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
