.root {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.main {
  width: 100%;
  margin: auto;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

.topTextContainer {
  z-index: 3;
  margin: auto;
  margin-bottom: -5vh;
  animation: fadeIn 5s;
}

.imageContainer {
  margin: auto;
  z-index: 2;
  text-align: center;
  margin-bottom: -5vh;
  animation: fadeIn 5s;
}

.bottomTextContainer {
  margin: auto;
  z-index: 3;
  text-align: center;
  margin-bottom: 5vh;
  animation: fadeIn 5s;
  width: 100%;
}

.text {
  font-size: 5vw;
}

.word {
  margin-right: 0.2em;
  width: 4vw;
  font-size: 5vw;
  display: inline-block;
}

.footer {
  align-self: flex-end;
  margin: 0;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  font-size: 0.4em;
  /* bottom: env(safe-area-inset-bottom); */
}

.downarrows {
  z-index: 4;
  display: flex;
  width: 100%;
  justify-content: center;
  animation: bounce 0.5s infinite alternate;
}

img {
  max-width: 50vh;
  height: auto;
}

@media only screen and (max-width: 450px) {
  .topTextContainer {
    margin-bottom: 2vh;
    margin-top: -10vh;
  }
  img {
    max-width: 50vw;
  }
  .footer {
    justify-content: center;
    position: fixed;
    bottom: env(safe-area-inset-bottom);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-15px);
  }
}

.spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

