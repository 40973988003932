.cursor {
  width: 40px;
  height: 40px;
  border: 2px solid white;
  border-radius: 100%;
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 9999;
  transition: all 250ms ease;
  transition-property: opacity, background-color, transform, mix-blend-mode;
  mix-blend-mode: difference;
}

.cursorHovered {
  transform: translate(-50%, -50%) scale(1.5);
  background-color: white;
}

@media only screen and (max-width: 450px) {
  .cursor {
    display: none;
  }
}
